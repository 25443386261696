import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { Layout } from "../../components/layout"
import { TagCollection } from "../../components/tagCollection"
import { PostSnippet } from "../../types"
import { SEO } from "../../components/seo/"
import { getSrc, IGatsbyImageData } from "gatsby-plugin-image"

interface Post {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      title: string
      description: string
      tags: string[]
      img: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData
        }
      }
      imgAlt: string
      publishedDate: string
      icon: string
    }
  }
}

export const pageQuery = graphql`
  query BlogPostsWithTag($tag: String!) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { tags: { eq: $tag } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            tags
            imgAlt
            publishedDate
            img {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            icon
          }
        }
      }
    }
  }
`

interface QueryData {
  data: {
    allMarkdownRemark: {
      edges: Post[]
    }
  }
  pageContext: {
    tag: string
  }
}

export const Page: FunctionComponent<QueryData> = ({ data, pageContext }) => {
  const relatedPost: PostSnippet[] = data.allMarkdownRemark.edges.map(
    ({ node }) => ({
      title: node.frontmatter.title,
      summary: node.frontmatter.description,
      href: node.fields.slug,
      img: node.frontmatter.img.childImageSharp.gatsbyImageData,
      imgAlt: node.frontmatter.imgAlt,
      tags: node.frontmatter.tags,
      publishedDate: new Date(node.frontmatter.publishedDate),
      icon: node.frontmatter.icon,
    })
  )
  const src = getSrc(relatedPost[0].img)

  return (
    <>
      <SEO title={pageContext.tag} image={src} />
      <Layout title={pageContext.tag}>
        <TagCollection tag={pageContext.tag} relatedPosts={relatedPost} />
      </Layout>
    </>
  )
}

export default Page
